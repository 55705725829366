/*
 * Body redefinition to handle fixed top bar
 */
body {
  font-family: 'Lato';
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

/*
 * Table custom styles
 */
table.dataTable tr {
  height: 30px; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
  top: 6px; }

.fa-btn {
  margin-right: 6px; }

/*
 * Button container definitions
 */
#button_container {
  display: block;
  padding: 0 0 20px 0;
  width: 100%; }

.tool_btn {
  float: left;
  margin-right: 2px;
  margin-bottom: 5px; }

.tool_btn.disabled {
  pointer-events: none; }

.dt-buttons {
  float: right;
  margin-top: -2px; }

/*
 * Payment date range
 */
.payment-daterange-container {
  display: inline-block;
  text-align: center;
  width: 40%; }

.payment-daterange {
  display: inline-table;
  margin-top: -2px;
  width: 290px; }

/*
 * Filter container definitions
 */
#filter_container {
  background: lightyellow none repeat scroll 0 0; }

#event_filters {
  margin: 0 35px 10px;
  border: 1px solid lightgrey; }

.pending_fee_filter {
  margin: 0 35px 10px; }
  .pending_fee_filter input {
    margin-left: 16px; }

.disabled_filters {
  pointer-events: none;
  opacity: 0.4; }

.fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 20px 10px 10px 10px;
  margin-bottom: 2em; }

.fieldset h1 {
  position: absolute;
  top: 0;
  font-size: 16px;
  line-height: 1;
  margin: -8px 0 0;
  /* half of font-size */
  background: lightyellow;
  padding: 0 3px; }

/*
 * File import button (to customize file input tag)
 */
.custom-file-import {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  float: left; }

.file-upload {
  display: none !important; }

.upload-file {
  position: relative;
  overflow: hidden; }

.upload-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block; }

/*
 * Modal forms
 */
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background-color: #2e6da4;
  color: white;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.modal-body p {
  padding-left: 30px; }

.bootbox .modal-footer {
  clear: right; }

.bootbox-body {
  font-size: 120%; }

.modal-icon {
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px; }

.modal-header .close {
  opacity: 0.8;
  color: #fff; }

form .modal-footer {
  padding: 15px 15px 0; }

form .modal-footer .form-group {
  margin-bottom: 0px; }

.help-block {
  padding-left: 15px; }

.form-horizontal .form-group .form-group .help-block {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0; }

.form-horizontal .form-group .form-group {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0; }

.form-horizontal ul {
  margin-bottom: 0; }

.form-group {
  margin-bottom: 10px; }

.newsletter-group {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-left: 15px;
  padding-bottom: 5px;
  padding-left: 0;
  width: 169px;
  margin-right: 15px; }

.institution-group {
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-left: 15px;
  padding-bottom: 5px;
  padding-left: 0;
  width: 117px;
  margin-right: 15px;
  padding-right: 0; }
  .institution-group .checkbox-inline, .institution-group .radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 8px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer; }

.active-group {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-left: 16px;
  padding-bottom: 5px;
  padding-left: 0;
  width: 169px; }

fieldset.payer-data, fieldset.member-data {
  border: 1px groove #ddd !important;
  box-shadow: 0 0 0 0 #000;
  margin: -10px 0 1em 3px !important;
  padding: 0 1.4em 0 2px !important; }

legend.payer-data, legend.member-data {
  border-bottom: medium none navy;
  font-size: 110%;
  font-weight: bold !important;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 8px 10px 0;
  text-align: left !important;
  width: auto; }

.payer-data .checkbox-inline label, .member-data .checkbox-inline label {
  padding: 0 0 0 20px;
  font-size: 90%; }

.payer-data .checkbox-inline, .member-data .checkbox-inline {
  padding-top: 0; }

/*
 * Filters
 */
.column_filter {
  background: ivory none repeat scroll 0 0; }

/*
 * Page footer
 */
.footer {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: auto;
  margin-top: 0;
  padding-right: 10px;
  box-shadow: 6px -5px 5px 0px rgba(0, 0, 0, 0.075);
  text-align: center;
  z-index: 100; }

.footer-text {
  margin: 5px; }

/*
 * Animated spinner
 */
#spinner, .spinner {
  width: 100%;
  height: 100%;
  background-image: url("/images/spinner.gif");
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  z-index: 1000;
  display: block;
  top: 0; }

.vertical-scrollbar {
  overflow-x: hidden;
  /*for hiding horizontal scroll bar*/
  overflow-y: auto;
  /*for vertical scroll bar*/ }

.row-no-padding [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.row-no-margin [class*="col-"] {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.dataTables_paginate.paging_input .paginate_button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #337ab7;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  cursor: pointer; }

.dataTables_paginate.paging_input > .paginate_button.disabled {
  background-color: #fff;
  border-color: #ddd;
  color: #777;
  cursor: not-allowed; }

.dataTables_paginate.paging_input .paginate_page, .dataTables_paginate.paging_input .paginate_of {
  background-color: #fff;
  float: left;
  line-height: 1.42857;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  cursor: default; }

.dataTables_paginate.paging_input .paginate_input {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #555;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  cursor: text;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 4px;
  height: 34px; }

.dataTables_paginate.paging_input .paginate_input:focus {
  border-color: #66afe9;
  outline: 0 none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6); }

.dataTables_paginate.paging_input .paginate_input {
  width: 80px;
  text-align: right; }

div.dataTables_wrapper div.dataTables_paginate {
  float: right;
  margin: 2px 0 8px; }

table.dataTable tbody th, table.dataTable tbody td {
  -moz-user-select: none; }

.faq_header {
  margin-left: 60px; }

/*Add some spacing*/
.faq-section {
  font-family: "Oswald", sans-serif !important;
  margin: 20px 0;
  position: relative;
  width: 90%;
  padding-left: 10%; }

/*Hide the paragraphs*/
.faq-section p, .faq-section ul li {
  display: none; }

.faq-section img {
  border: 1px dashed black;
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto; }

/*Hide the checkboxes */
.faq-section input {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  display: none\9;
  /* IE8 and below */
  margin: 0;
  width: 100%;
  height: 36px; }

/*Show only the clipped intro */
.faq-section label + p {
  display: block;
  color: #19f;
  font-size: .85em;
  transition: all .15s ease-out;
  /* Clipping text */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

/*If the checkbox is checked, show all paragraphs*/
.faq-section input[type=checkbox]:checked ~ p, .faq-section input[type=checkbox]:checked ~ ul li {
  display: block;
  color: #444;
  font-size: 1em;
  /* restore clipping defaults */
  text-overflow: clip;
  white-space: normal;
  overflow: visible; }

.faq-section input[type=checkbox]:checked ~ ul li {
  display: list-item;
  color: #444;
  font-size: 1em;
  /* restore clipping defaults */
  text-overflow: clip;
  white-space: normal;
  overflow: visible; }

/*Style the label*/
.faq-section label {
  font-size: 1.2em;
  background: #eee;
  display: block;
  position: relative;
  height: auto;
  padding: 7px 10px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-left: 3px solid #888;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  transition: all .15s ease-out; }

/*Remove text selection when toggle-ing*/
.faq-section label::selection {
  background: none; }

.faq-section label:hover {
  background: #f5f5f5; }

/*If the checkbox is checked, style the label accordingly*/
.faq-section input[type=checkbox]:checked ~ label {
  border-color: #7d99ca;
  background: #c8d7dc;
  background-image: linear-gradient(to bottom, #f2f5f6, #c8d7dc);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4); }

/*Label's arrow - default state */
.faq-section label::before {
  content: '';
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -6px;
  border: 6px solid transparent;
  border-left-color: inherit; }

/*Update the right arrow*/
.faq-section input[type=checkbox]:checked ~ label::before {
  border: 6px solid transparent;
  border-top-color: inherit;
  margin-top: -3px;
  right: 10px; }

/*# sourceMappingURL=app.css.map */
