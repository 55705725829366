.faq_header {
    margin-left: 60px;
}

/*Add some spacing*/
.faq-section{
font-family: 'Oswald', sans-serif ! important;
    margin: 20px 0;
    position: relative;
    width: 90%;
    padding-left: 10%;
}

/*Hide the paragraphs*/
.faq-section p{
    display: none;
}

.faq-section img {
    border: 1px dashed black;
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

/*Hide the checkboxes */
.faq-section input{
    position: absolute;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    display: none\9; /* IE8 and below */
    margin: 0;
    width: 100%;
    height: 36px;
}

/*Show only the clipped intro */
.faq-section label+p{
    display: block;
    color: #19f;
    font-size: .85em;
    transition: all .15s ease-out;
    /* Clipping text */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/*If the checkbox is checked, show all paragraphs*/
.faq-section input[type=checkbox]:checked~p{
    display: block;
    color: #444;
    font-size: 1em;
    /* restore clipping defaults */
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
}

/*Style the label*/
.faq-section label{
    font-size: 1.2em;
    background: #eee;
    display: block;
    position: relative;
    height: auto;
    padding: 7px 10px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-left: 3px solid #888;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    transition: all .15s ease-out;
}

/*Remove text selection when toggle-ing*/
.faq-section label::selection{
    background: none;
}

.faq-section label:hover{
    background: #f5f5f5;
}

/*If the checkbox is checked, style the label accordingly*/
.faq-section input[type=checkbox]:checked~label{
    border-color: #7d99ca;
    background: #c8d7dc;
    background-image: linear-gradient(to bottom, #f2f5f6, #c8d7dc);
    box-shadow: 0 0 1px rgba(0,0,0,.4);
}

/*Label's arrow - default state */
.faq-section label::before{
    content: '';
    position: absolute;
    right: 4px;
    top: 50%;
    margin-top: -6px;
    border: 6px solid transparent;
    border-left-color: inherit;
}

/*Update the right arrow*/
.faq-section input[type=checkbox]:checked~label::before{
    border: 6px solid transparent;
    border-top-color: inherit;
    margin-top: -3px;
    right: 10px;
}