// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
 * Body redefinition to handle fixed top bar
 */
body {
    //font-family: 'Oswald', sans-serif;
    font-family: 'Lato';
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

/*
 * Table custom styles
 */
table {
    //font-family: 'Source Sans Pro', sans-serif;    
}

.fa-btn {
    margin-right: 6px;
}

/*
 * Button container definitions
 */
#button_container {
    display: block; 
    padding: 0 0 40px 0;
}
.tool_btn {
    float: left;
    margin-right: 2px;
}
.tool_btn.disabled {
    pointer-events: none;
}
.dt-buttons {
    float: right;
}

/*
 * Filter container definitions
 */
#filter_container {
    background: lightyellow none repeat scroll 0 0;
}

/*
 * File import button (to customize file input tag)
 */
.custom-file-import {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    float: left;
}
.file-upload {
    display: none !important;
}

/*
 * Modal forms
 */
.modal-header {
    padding:9px 15px;
    border-bottom:1px solid #eee;
    background-color: #2e6da4;
    color: white;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
 }
.modal-body p {
    padding-left: 30px;
}
.modal-content .fa.fa-question-circle {
    float: right;
    margin-top: 10px;
    margin-right: 20px;
}
.modal-header .close {
    opacity: 0.8;
    color: #fff;
}
.modal-footer {
    padding: 15px 15px 0;
}
.modal-footer .form-group {
    margin-bottom: 0px;
}
.help-block {
    padding-left: 15px;
}
.form-horizontal .form-group .form-group .help-block {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.form-horizontal .form-group .form-group {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}
.form-horizontal ul {
    margin-bottom: 0;
}
.form-group {
    margin-bottom: 10px;
}
.newsletter-group {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-left: 15px;
    padding-bottom: 5px;
    padding-left: 0;
    width: 169px;
    margin-right: 15px;
}
.active-group {
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-left: 16px;
    padding-bottom: 5px;
    padding-left: 0;
    width: 169px;
}

/*
 * Filters
 */
.column_filter {
    background: ivory none repeat scroll 0 0;
}

/*
 * Page footer
 */
.footer {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: auto;
    margin-top: 0;
    padding-right: 10px;
    -webkit-box-shadow: 6px -5px 5px 0px rgba(0,0,0,0.075);
    -moz-box-shadow: 6px -5px 5px 0px rgba(0,0,0,0.075);
    box-shadow: 6px -5px 5px 0px rgba(0,0,0,0.075);
    text-align: center;
    z-index: 100;
}
.footer-text {
    margin: 5px;
}

/*
 * Animated spinner
 */
#spinner {
	width: 100%;
	height: 100%;
	background-image: url('/images/spinner.gif');
	background-repeat: no-repeat; 
	background-position: center; 
	position: absolute; 
	z-index: 1000; 
	display: block;
	top: 0;
}
